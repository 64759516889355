import './App.css';
import token from './abis/token.json'
import minting from './abis/minting.json'
import logo from './images/logo.png'
import moc from './images/emp.jpg'
import mocText from './images/Grape.jpg'
import dc from './images/icons/dc.png'
import tw from './images/icons/tw.png'
import insta from './images/icons/insta.png'
import {ethers} from 'ethers'
import { useEffect, useState } from 'react';

function App() {
  const pos = {x: 0, y: 0}
  var bright = 100
  const mintingAddress="0xB4ce1dF0ed8Ae2F542cB5c121c064f11E4e7c13E"
  const tokenAddress="0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
  var [account, setAccount] = useState("Connect Wallet")
  var [amount, setAmount] = useState("")
  var [totalMinted, setTotalMinted] = useState(0)
  var [mintText, setmintText] = useState("Mint")
  useEffect(()=>{
    getInfo()
    setInterval(() => getInfo(), 10000)
    document.addEventListener('mousemove', e=> {saveCursorPosition(e.clientX, e.clientY)})
  },[])

  async function saveCursorPosition(x,y){
    pos.x = ((x/window.innerWidth)-0.5).toFixed(2)
    pos.y = ((y/window.innerHesight)-0.5).toFixed(2)
    bright = Math.abs(((x-y)/40)+95).toFixed(2)
    document.documentElement.style.setProperty('--x', -50*pos.x+"deg")
    document.documentElement.style.setProperty('--y', 50*pos.y+"deg")
    document.documentElement.style.setProperty('--bright', bright+"%")
  }
  async function errorHandler(ex){
    if(ex.error === undefined){
      alert(ex.reason)
    }else{
      alert(ex.error.data.message)
    }
  }
  async function amountHandler(e){
    if(e.target.value > 500){
      alert("Please enter an amount less than 20.")
      setAmount(500)
    }else if(e.target.value <0){
      alert("Please enter an amount greater than 0.")
      setAmount("")
    }else{
      setAmount(e.target.value)
    }
  }
  async function connect(){
    if(typeof window.ethereum !== 'undefined'){
      try{
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const chainId = await provider.getNetwork()
        if(chainId.chainId !== 43114 ){
          alert("Please connect to AVAX network!")
          window.location.reload()
        }else{
          const accounts = await provider.send("eth_requestAccounts", [])
          setAccount(accounts[0].slice(0,7)+"...")
          window.ethereum.on('chainChanged', e => window.location.reload())
          window.ethereum.on('accountsChanged', e => window.location.reload())
        }
      }catch(ex){
        alert(ex.message)
      }
    }else{
      alert("Please install MetaMask or try using MetaMask Browser!")
    }
  }
  async function mint(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts", [])
    const tokenContract = new ethers.Contract(tokenAddress, token.abi,provider.getSigner())
    const mintingContract = new ethers.Contract(mintingAddress, minting.abi,provider.getSigner())

    //check Allowance and mint
    const allowanceBigNumber = await tokenContract.allowance(accounts[0],mintingAddress)
    const allowance = ethers.utils.formatUnits(allowanceBigNumber,6)
    if(allowance < amount*3){
      try{
        const toApprove = ethers.utils.parseUnits((amount*50).toString(),6)
        console.log(toApprove)
        setmintText("Please approve $USDC")
        const approve = await tokenContract.approve(mintingAddress, toApprove)
        setmintText("Please wait")
        await approve.wait()
        setmintText("Please pay $USDC")
        const mint = await mintingContract.mint(amount)
        setmintText("Please wait")
        await mint.wait()
        alert(`You have successfully minted ${amount} NFT(s)`)
      }catch(ex){
        errorHandler(ex)
      }finally{
        setmintText("Mint")
      }
    }else{
      try{
        setmintText("Please pay $USDC")
        const mint = await mintingContract.mint(amount)
        setmintText("Please wait")
        await mint.wait()
        alert(`You have successfully minted ${amount} nft(s)`)
      }catch(ex){
        errorHandler(ex)
      }finally{
        setmintText("Mint")
      }
    }
  }
  async function getInfo(){
    const provider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/avalanche")
    const mintingContract = new ethers.Contract(mintingAddress, minting.abi,provider)
    const mintedBigNumber = await mintingContract.totalSupply()
    const minted = ethers.utils.formatUnits(mintedBigNumber,0)
    document.documentElement.style.setProperty('--progress', minted*100/1000+"%")
    setTotalMinted(minted)
  }
  return (
    <div className="App">
      <div id='Topbar'>
        <a href='https://magik.finance'>
          <h1>Magik Finance</h1>
        </a>
        <div id = 'Menu'>
        <a href='https://worldofmagik.com'>World Of Magik Game!</a>
        <a href='https://magikdotfinance.gitbook.io/magik-arena/'>Documentation</a>
        <a href='https://linktr.ee/worldofmagik'>Important Links</a>
        <a href='https://twitter.com/worldofmagik'>Twitter</a>
        </div>
        <button onClick={connect}>{account}</button>
      </div>
      <div id = 'ArenaText' style={{ display:"grid", justifySelf:"center", gap:"10px",margin:"20px 50px 30px 50px"}}>
      <h1>Magik Arena NFT</h1>
        <h1>Grape Partner Mint</h1>
        <h1>Please Connect to AVAX!</h1>
        <h3>(Items and Tokens to be Airdropped Upon January Beta Launch)</h3>
        <div id='Farmland' style={{display:"grid",justifySelf:"center", gap:"10px",margin:"20px 50px 50px 50px"}}>
        <h2> 1 NFT Grants you:</h2>
        <div id ='Container'>
        <h3>MAGIK for Games</h3>
        <h3>Rare Faction Weapon</h3>
        <h3>Faction Benefits</h3>
        <h3>NFT Staking </h3>
        </div>
        </div>
      {/* <img style={{width:"170px", justifySelf:"center"}} src={logo} alt="logo"/> */}
      {/* <button onClick={connect}>{account}</button> */}
      </div>
      <div id='Farmland' >
        <img id='Farmland' src={mocText} alt="mocText"/>
        <div id=''>
          <div id=''/>
        </div>
        <div id='Righthand'>
        <button onClick={() => window.open("https://magiknft.magik.finance/")}>Switch To Magik (FTM) Mint</button>
        <button onClick={() => window.open("https://frozenwalrusnft.magik.finance")}>Switch To Frozen Walrus (AVAX) Mint</button>
        <button onClick={() => window.open("https://grapenft.magik.finance")}>Switch To Grape (AVAX) Mint</button>
        <button onClick={() => window.open("https://verteknft.magik.finance")}>Switch To Vertek (BSC) Mint</button>
        <button onClick={() => window.open("https://empnft.magik.finance")}>Switch To EMP (BSC) Mint</button>
        <span id='Lefthand'>Total Minted: {totalMinted}</span>
        <input id= "input" disabled={account === "Connect Wallet"} value={amount} type="number" onChange={e=> amountHandler(e)} placeholder='Enter Amount'></input>
        <span id='Lefthand'>Mint Price: 50 $USDC</span>
        <button disabled={account === "Connect Wallet" || mintText !== "Mint"} onClick={mint}>{mintText}</button>
        </div>
        {/* <img id='MOC' src={moc} alt="moc"/> */}
        <div id='Topbar button' >
          <a href='https://t.co/qCQBqVTSA5'>
          <img src={dc} alt="dc"/>  
          </a>
          <a href='https://twitter.com/Magikthedog'>
            <img src={tw} alt="tw"/>
            </a>
          
        </div>
      </div>
      
    </div>
  );
}

export default App;
